import $ from 'jquery';

//import Aos from 'aos';
import StickySidebar from 'sticky-sidebar';


// Import only the Bootstrap components we need
//import { Util, Dropdown, Offcanvas, Popover } from 'bootstrap';
//import { Navbar } from 'bootstrap';

//Dependences

//import Swiper, {Navigation, Pagination} from 'swiper';

//import 'aos/dist/aos.css'
import '../scss/style.scss';


import './partials/searching';

const scrollbarWidth = window.innerWidth - document.body.clientWidth
let headerheight = document.querySelector('.main-header').offsetHeight;
document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`)
document.body.style.setProperty("--headerheight", `${headerheight}px`)


$(document).ready(function() {
  $(window).on('resize', function(){
    headerheight = document.querySelector('.main-header').offsetHeight;
    document.body.style.setProperty("--headerheight", `${headerheight}px`)
  })

  $('.hamburger.mobile-menu').on('click',function(e){
    //console.log('hamburger');
    e.preventDefault();
    $(this).toggleClass('is-active')
    $('.mobile-menu-wrap').toggleClass('show')
    $('.main-header').toggleClass('menu-show')
    //$('.main-header').toggleClass('open')
  })


  $('.mobile-menu-wrap .has-children > .nav-link, .mobile-menu-wrap .has-children > .show-submenu').on('click',function(e){
    e.preventDefault();
    $(this).closest('.has-children').toggleClass('active');
    $(this).closest('.has-children').find('> .show-submenu').toggleClass('active');
    $(this).closest('.has-children').find('> .sub-menu').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  var scroll = 0;
  $(window).on('scroll',function(){
    let curscroll = $(window).scrollTop();
    if (curscroll > scroll){
      // downscroll code
      $('.main-header').removeClass('scrollup');
    } else {
      // upscroll code
      $('.main-header').addClass('scrollup');
    }
    if ((curscroll > 100) ) {
      $('.main-header').addClass('scroll');
    }else {
      $('.main-header').removeClass('scroll');
      $('.main-header').removeClass('scrollup');
        
    }
    scroll = curscroll;
  })


  $('.anime-scroll').on('click',function(e){
    e.preventDefault();
    let thishas = $(this).find('.nav-link').attr('href')
    $('html, body').animate({scrollTop: $(thishas).offset().top - 110}, 1000);
  })

  $('.mobile-side-menu .search-icon-wrap').on('click',function(e){
    e.preventDefault();
    $(this).closest('.middle-header-wrap').find('.search-wrap').toggle(0)
  })
  

 /*  Aos.init({
    duration: 900,
  }); */

  let stickyCat = null;
  if ( $('.js-sticky-sidebar').length) {
    let sidebar_height = $('.js-sticky-sidebar').height();
    let row_height = $('.js-sticky-sidebar').closest('.row').height();
    if(sidebar_height < row_height) {
      if ($(window).width() > 992) {
        stickyCat = new StickySidebar('.js-sticky-sidebar', {
          //listen: true,
          topSpacing: headerheight+20,
          //bottomSpacing: 0,
          containerSelector: '.row',
          resizeSensor: true,
        })
      }  
    }
    
    $(window).on('resize', function(){
      let sidebar_height = $('.js-sticky-sidebar').height();
      let row_height = $('.js-sticky-sidebar').closest('.row').height();
      if(sidebar_height< row_height) {
        if ($(window).width() > 992) {
          if(stickyCat == null){
            if ( $('.js-sticky-sidebar').length) {
              stickyCat = new StickySidebar('.js-sticky-sidebar', {
                //listen: true,
                topSpacing: headerheight+20,
                bottomSpacing: 0,
                containerSelector: '.row',
                resizeSensor: true,
              })
            }
          }
        }else {
          if (stickyCat) {
            stickyCat.destroy();
            stickyCat = null;
          }
          
        }  
      }else {
        if (stickyCat) {
          stickyCat.destroy();
          stickyCat = null;
        }
      }
      
    })  
  }


  $('.contact-form').on('submit',function(e){
    e.preventDefault();
    let form = $(this);
    $(this).addClass('form-validate');
   /*  $('.be-error').hide();
    $('.error').hide();
    $('.form-control-wrap').removeClass('form-control-wrap--error')
    $('.kontakt-form-wrap .error-response').hide()
    $('.kontakt-form-wrap .success-response').hide() */
    let isValid = true;
    $('.form-control, .form-check-input',form).each(function() {
      $(this).removeClass('is-invalid')    
      $(this).closest('.form-floating ').removeClass('is-invalid')  
      if ($(this).is(":invalid")) {
        $(this).addClass('is-invalid')    
        $(this).closest('.form-floating ').addClass('is-invalid')    
        /* $(this).closest('.form-control-wrap').addClass('form-control-wrap--error')
        $(this).closest('.form-group').find('.error').show(); */
        isValid = 0;
      }
    });
    if (isValid) {
      $('.loading-loader').show();
      //const formData = new FormData();
      let data = $(this).serialize();
      //formData.push(data);
      //let file =  $('.form-control[name="subor"]',$(this))[0].files[0];
      //formData.append('file', file);
      //console.log(file)
      $.ajax({
        url: ajax_url,
        type: "post",
        dataType: "json",
        data: new FormData(this),
        processData: false,
        contentType: false,
        success: function(data) {
          //console.log(data);
          $('.loading-loader').hide();
          //console.log(data['responce'])
          if (data['responce'] == 'success') {
            $(form).closest('.contact-form-wrap').find('.success-response').show()
          }else {
            $(form).closest('.contact-form-wrap').find('.error-response').show()
          }
        }
      })
    }
  });

});




function setCookieTrial() {
  var d = new Date();
  d.setTime(d.getTime() + (2*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = "noticepopup=click;" + expires;
}


function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}
